import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "user",
    loadChildren: () => import("../../components/user/user.module").then((m) => m.UserModule),
  },
  {
    path: "event",
    loadChildren: () => import("../../components/event/event.module").then((m) => m.EventModule),
  },
];
