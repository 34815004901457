import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public userRole: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router) {
    this.loadUserData();
  }

  loadUserData() {
    const token = localStorage.getItem('token');
    if (token) {
      const userData = JSON.parse(atob(token.split('.')[1]));
      this.userName = userData.name || 'User';
      this.userRole = userData.role || 'User';
    } else {
      this.userName = 'Guest';
      this.userRole = 'Guest';
    }
  }

  ngOnInit() { }

  logoutFunc() {
    this.router.navigateByUrl('/login');
    localStorage.removeItem('token');
  }
}
