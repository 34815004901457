import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { MaintenanceService } from './shared/services/maintenance.service';
// import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private maintenanceService: MaintenanceService,
    private router: Router
  ) {  }


  ngOnInit() {
    // Check backend for maintenance status
    this.maintenanceService.checkMaintenanceStatus().subscribe(status => {
      if (status.maintenance) {
        // Redirect to the maintenance page if backend reports maintenance mode
        this.router.navigate(['/maintenance']);
      }
    });

     // Poll for maintenance status every 30 seconds
  setInterval(() => {
    this.maintenanceService.checkMaintenanceStatus().subscribe(status => {
      if (status.maintenance) {
        this.router.navigate(['/maintenance']);
      }
    });
  }, 30000);
  }

}
