<div class="media profile-media">
  <div class="media-body">
    <span>{{ userName }}</span>
    <p class="mb-0 font-roboto">{{ userRole }} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li (click)="logoutFunc()">
    <app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span>
  </li>
</ul>