import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import { LoginComponent } from "./auth/login/login.component";
import { RoleGuard } from "./auth/role.guard";
import { MaintenanceComponent } from "./auth/maintenance/maintenance.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: 'maintenance', // Maintenance route
    component: MaintenanceComponent
  },
  {
    path: "",
    canActivate: [RoleGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
        data: { role: "admin" }
      },
      {
        path: "",
        redirectTo: "event/inscription",
        pathMatch: "full",
        data: { role: "client" }
      }
    ]
  },
  {
    path: "",
    component: ContentComponent,
    children: content
  },
  {
    path: "",
    component: FullComponent,
    children: full
  },
  {
    path: "**",
    canActivate: [RoleGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
        data: { role: "admin" }
      },
      {
        path: "",
        redirectTo: "event/inscription",
        pathMatch: "full",
        data: { role: "client" }
      }
    ]
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
