import { Component, OnInit } from '@angular/core';
import { NavService } from '../../../../services/nav.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;

  public languages: any[] = [
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr'
    }
  ]

  public selectedLanguage: any = {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  }


  constructor(public navServices: NavService) { }
  // 
  ngOnInit() {
  }

  changeLanguage(lang) {
    this.selectedLanguage = lang;
  }

}
