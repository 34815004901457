import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const token = localStorage.getItem('token'); // Assurez-vous que c'est le bon nom de clé
        let role = '';

        if (token) {
            const decodedToken: any = jwtDecode(token);
            if (decodedToken && decodedToken.role) {
                // Décoder le token et extraire le rôle
                // Ceci est un exemple, ajustez selon la structure de votre token
                //   const decodedToken = JSON.parse(atob(token.split('.')[1]));
                role = decodedToken.role;
            }
            else {
                role = 'user';
            }
        }

        if (role === route.data['role']) {
            return true;
        } else if (role === 'admin') {
            return this.router.createUrlTree(['/dashboard']);
        } else if (role === 'client') {
            return this.router.createUrlTree(['/event/inscription']);
        } else {
            // Si le rôle n'est pas reconnu, redirigez vers login ou une page d'erreur
            return this.router.createUrlTree(['/login']);
        }
    }
}