import { Component,OnInit } from "@angular/core";
import { MaintenanceService } from "src/app/shared/services/maintenance.service";
import { Router } from '@angular/router';


@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.scss"],
})
export class MaintenanceComponent implements OnInit {

    constructor(private maintenanceService:MaintenanceService,private router:Router){}

    ngOnInit() {
        // Check backend for maintenance status
        this.maintenanceService.checkMaintenanceStatus().subscribe(status => {
          if (!status.maintenance) {
            // Redirect to the maintenance page if backend reports maintenance mode
            this.router.navigate(['/login']);
          }
        });
    // Poll for maintenance status every 30 seconds
    setInterval(() => {
        this.maintenanceService.checkMaintenanceStatus().subscribe(status => {
          if (!status.maintenance) {
            this.router.navigate(['/login']);
          }
        });
      }, 30000);
              
    }
}